var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "team-list-panel" }, [
    _c(
      "div",
      { staticClass: "team-list" },
      [
        _vm._l(_vm.records, function (team, i) {
          return _c("div", { key: i, staticClass: "team-container" }, [
            _c("div", { staticClass: "team" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(team.name) + " "),
              ]),
              _c("div", { staticClass: "location" }, [
                _c("div", { staticClass: "entry" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("所在单位：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(" " + _vm._s(team.institution) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "entry" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("所属国家：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(" " + _vm._s(team.country.name) + " "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "link" }, [
                _c("div", { staticClass: "label" }, [_vm._v("课题组链接：")]),
                _c("div", { staticClass: "url" }, [
                  _c("a", { attrs: { href: team.url } }, [
                    _vm._v(_vm._s(team.url)),
                  ]),
                ]),
              ]),
            ]),
          ])
        }),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                id: "pagination",
                "current-page": _vm.pager.index,
                "page-sizes": [10, 50, 100],
                "page-size": _vm.pager.size,
                layout: "prev, pager, next, jumper,sizes",
                total: _vm.pager.total,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.pager, "index", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.pager, "index", $event)
                },
                "update:pageSize": function ($event) {
                  return _vm.$set(_vm.pager, "size", $event)
                },
                "update:page-size": function ($event) {
                  return _vm.$set(_vm.pager, "size", $event)
                },
                "current-change": _vm.query,
                "size-change": _vm.sizeChange,
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }