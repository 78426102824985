<template>
	<div class="team-list-panel">
		<div class="team-list">
			<div class="team-container" v-for="(team, i) in records" :key="i">
				<div class="team">
					<div class="title">
						{{ team.name }}
					</div>
					<div class="location">
						<div class="entry">
							<div class="label">所在单位：</div>
							<div class="value">
								{{ team.institution }}
							</div>
						</div>
						<div class="entry">
							<div class="label">所属国家：</div>
							<div class="value">
								{{ team.country.name }}
							</div>
						</div>
					</div>
					<div class="link">
						<div class="label">课题组链接：</div>
						<div class="url">
							<a :href="team.url">{{ team.url }}</a>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination
					id="pagination"
					:current-page.sync="pager.index"
					:page-sizes="[10, 50, 100]"
					:page-size.sync="pager.size"
					layout="prev, pager, next, jumper,sizes"
					:total="pager.total"
					@current-change="query"
					@size-change="sizeChange"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi } from "../../../../api"

export default {
	data() {
		return {
			userCenterApi: new UserCenterApi(ApiClient.instance),
			records: [],
			total: 0,
			pager: {
				index: 1,
				size: 10,
				total: 10
			}
		}
	},
	mounted() {
		this.query()
	},
	methods: {
		query() {
			this.userCenterApi.apiUserCenterFavoritesGet(
				5,
				{
					index: this.pager.index,
					size: this.pager.size
				},
				(e, data, resp) => {
					data = resp.body
					if (data.success) {
						this.records = data.result.records
						this.pager.total = data.result.total
					}
				}
			)
		},
		sizeChange(size) {
			this.pager.size = size
			this.pager.index = 1
			this.query()
		}
	}
}
</script>

<style lang="less" scoped>
.top-bar-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.top-bar {
	margin: 10px 20px;
}

.result {
	.c-font-size-12();
	.c-font-family-PF();
	line-height: 16px;
	color: #666666;
	display: flex;
	flex-direction: row;
}

.result .value {
	color: #3463ea;
	margin: 0 6px;
}

.team-list {
	width: 100%;
}

.team-container {
	width: 100%;
	display: inline-block;
	background-color: #ffffff;
	margin-top: 16px;
}

.team {
	margin: 16px;
	position: relative;
}

.team .title {
	.c-font-size-12();
	color: #3463ea;
	line-height: 20px;
	text-align: left;
}

.team .location {
	.c-font-size-12();
	line-height: 20px;
	display: flex;
	flex-direction: row;
	text-align: left;
}

.team .location .entry {
	display: flex;
	flex-direction: row;
	margin-right: 20px;
}

.team .location .entry .value {
	margin-left: 6px;
}

.team .link {
	display: flex;
	flex-direction: row;
	.c-font-size-12();
	line-height: 20px;
}

.team .link .url {
	margin-left: 6px;
}

.team .sm-btn {
	position: absolute;
	.c-font-size-12();
	right: 10px;
	top: px;
	width: 45px;
	height: 20px;
	border-radius: 3px;
	border: 1px solid blue;
	color: blue;
	text-align: center;
	cursor: pointer;
}
.pagination {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}
</style>
